var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Upload file",
      "visible": _vm.isModalOpen,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isModalOpen = $event;
      },
      "close": _vm.handeModalClose
    }
  }, [_c('el-upload', {
    ref: "upload",
    attrs: {
      "action": "",
      "drag": "",
      "on-change": _vm.handleChangedFile,
      "on-remove": _vm.handeRemovedFile,
      "multiple": "",
      "auto-upload": false,
      "file-list": _vm.data.documents,
      "accept": ".png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, xlsx, .doc, .docx, .ppt, .pptx, .pdf"
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drop file here or "), _c('em', [_vm._v("click to upload")])]), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(" Accept files with extension .png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf ")])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.isBtnLoading,
      "disabled": _vm.isBtnDisabled || !_vm.data.documents.length
    },
    on: {
      "click": _vm.uploadNewFile
    }
  }, [_vm._v(" Upload ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }