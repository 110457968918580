var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex align-items-center mb-5",
    staticStyle: {
      "gap": "16px"
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go back ")]), _c('h5', [_vm._v(_vm._s(_vm.productName))])], 1), _c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix flex justify-content-end",
    staticStyle: {
      "gap": "8px"
    },
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('el-input', {
    staticStyle: {
      "width": "30%",
      "margin-right": "auto"
    },
    attrs: {
      "placeholder": "Type to search file name",
      "clearable": ""
    },
    model: {
      value: _vm.searchKeyword,
      callback: function ($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  }), _c('el-button', {
    attrs: {
      "icon": "el-icon-upload2",
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isUploadNewFileDialogOpen = !_vm.isUploadNewFileDialogOpen;
      }
    }
  }, [_vm._v(" Upload file ")])], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.pagedTableData.filter(function (data) {
        return !_this.searchKeyword || data.display.toLowerCase().includes(_this.searchKeyword.toLowerCase());
      })
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('a', {
          attrs: {
            "target": "_blank",
            "href": scope.row.file_url
          }
        }, [_vm._v(" " + _vm._s(scope.row.display) + " "), _c('i', {
          staticClass: "el-icon-top-right"
        })])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "label": "Type"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "size",
      "label": "Size"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "created_at",
      "label": "Uploaded At"
    }
  }), _c('el-table-column', {
    staticClass: "flex",
    staticStyle: {
      "gap": "16px"
    },
    attrs: {
      "label": "Actions"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-popconfirm', {
          attrs: {
            "confirm-button-text": "Yes, delete",
            "cancel-button-text": "No, cancel",
            "icon": "el-icon-warning",
            "icon-color": "red",
            "confirm-button-type": "danger",
            "cancel-button-type": "text",
            "title": "Are you sure to delete this?"
          },
          on: {
            "confirm": function ($event) {
              return _vm.handleDelete(scope.$index, scope.row);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "size": "small",
            "loading": _vm.isBtnLoading
          },
          slot: "reference"
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 20
          }
        })], 1)], 1)];
      }
    }])
  })], 1), _c('Pagination', {
    attrs: {
      "array": _vm.productDetailFilesTable
    },
    on: {
      "update-table-page": _vm.handleTablePage,
      "update-table-limit": _vm.handleTableLimit
    }
  })], 1), _c('UploadFileDetailModal', {
    attrs: {
      "data": _vm.uploadNewFileForm
    },
    on: {
      "bind-table-data": _vm.bindTableData
    },
    model: {
      value: _vm.isUploadNewFileDialogOpen,
      callback: function ($$v) {
        _vm.isUploadNewFileDialogOpen = $$v;
      },
      expression: "isUploadNewFileDialogOpen"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }